<template>
  <a-modal width="80%" wrap-class-name="full-modal" :visible="true" :footer="null" title="Transaction">
    <FormValidationWrap>
      <VerticalFormStyleWrap>
        <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmit" :layout="formState.layout">
          <a-row :gutter="25">
            <a-col :span="12">
              <a-form-item label="Bank Account" name="bankAccountId">
                <a-select v-model:value="formState.bankAccountId" placeholder="Bank Account">
                  <a-select-option v-for="(bank, index) in AccountApi" :key="index" :value="bank.id">
                    {{ bank.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Transaction Type" name="bankAccounttype">
                <a-select v-model:value="formState.bankAccounttype" placeholder="Bank Account">
                  <a-select-option value="e">
                    In</a-select-option>
                  <a-select-option value="e">
                    Out</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Choix moyen de paiemen" name="bankAccounttype">
                <a-select v-model:value="formState.bankAccounttype" placeholder="Bank Account">
                  <a-select-option value="e">
                    In</a-select-option>
                  <a-select-option value="e">
                    Out</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Choix Class" name="bankAccounttype">
                <a-select v-model:value="formState.bankAccounttype" placeholder="Bank Account">
                  <a-select-option value="e">
                    In</a-select-option>
                  <a-select-option value="e">
                    Out</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Type of Expense" name="bankAccounttype">
                <a-select v-model:value="formState.bankAccounttype" placeholder="Bank Account">
                  <a-select-option value="e">
                    In</a-select-option>
                  <a-select-option value="e">
                    Out</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Benificiary " name="bankAccounttype">
                <a-select v-model:value="formState.bankAccounttype" placeholder="Bank Account">
                  <a-select-option value="e">
                    In</a-select-option>
                  <a-select-option value="e">
                    Out</a-select-option>
                </a-select>
              </a-form-item>

            </a-col>
            <a-col :span="12">
              <a-form-item label="Name " name="bankAccounttype">
                <a-select v-model:value="formState.bankAccounttype" placeholder="Bank Account">
                  <a-select-option value="e">
                    In</a-select-option>
                  <a-select-option value="e">
                    Out</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="12" :xs="12">
              <a-form-item ref="amount" label="Amount" name="amount">
                <a-input-number :style="{ width: '100%' }" size="small" v-model:value="formState.amount" />
              </a-form-item>
            </a-col>
            <a-col :md="12" :xs="12">
              <a-form-item ref="vat" label="Vat" name="vat">
                <a-input-number :style="{ width: '100%' }" :min="0" :max="100" :formatter="(value) => `${value}%`"
                  :parser="(value) => value.replace('%', '')" v-model:value="formState.vat" placeholder="Value" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <sdButton htmlType="submit" size="full" type="primary" key="submit" >
                <span v-if="!formState.editionMode">Add new</span>
                <span v-if="formState.editionMode">Edit bank operation</span>
              </sdButton>
            </a-col>
          </a-row>
        </a-form>
      </VerticalFormStyleWrap>
    </FormValidationWrap>
  </a-modal>
</template>
<script setup>
// vue import
import { reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";

// gLobal const
const { state, dispatch } = useStore();


// Life Cycle
onMounted(() => {
  dispatch("partnerInfo");
  dispatch("accountInfo");
});

// bankacount
const AccountApi = computed(() => state.AccountApi.data);


var formState = reactive({
  layout: "vertical",
  id: 0,
  name: "",
  date: "",
  estimatedTime: "",
  amount: null,
  expenseTypeId: null,
  bankAccountId: null,
  partenaireId: null,
  Note: "",
});


</script>
<style lang="scss">
.history-row {
  margin-top: 16px;
}

.history-row {
  th {
    text-align: left !important;
  }
}
</style>